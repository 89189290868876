.menu {
    border: none;
    cursor: pointer;
    height: 40px;
    padding: 0;
    position: fixed;
    right: 35px;
    top: 30px;
    transition: top 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    width: 40px;
}
.menu,
.menu:focus {
    background-color: #0000;
}
@media (min-width: 1024px) {
    .menu {
        top: 35px;
    }
}
.line {
    fill: none;
    stroke: #000;
    transition:
        stroke-dasharray 0.6s cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
.line,
.line1 {
    stroke-width: 6;
}
.line1 {
    stroke-dasharray: 60 207;
}
.line2 {
    stroke-dasharray: 60 60;
}
.line2,
.line3 {
    stroke-width: 6;
}
.line3 {
    stroke-dasharray: 60 207;
}
.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}
.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}
.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}